<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Lista de Casamento - Inclusão</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'store-gift-lists'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-store-gift-list :btnLabel="btnLabel"></form-store-gift-list>
</span>
</template>

<script>
import FormStoreGiftListComponent from "./partial/FormStoreGiftListComponent";

export default {
    name: "AddStoreGiftListComponent",
    data() {
        return {
            btnLabel: 'Incluir'
        }
    },
    methods: {

    },
    components: {
        formStoreGiftList: FormStoreGiftListComponent,
    }
};
</script>

<style scoped>

</style>
